// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containerLink_vMDrh{padding:.75rem 0}.containerAlignment_iYX9b{display:flex;justify-items:flex-end}.icon_xdpk5{transform:translateY(-2px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerLink": "containerLink_vMDrh",
	"containerAlignment": "containerAlignment_iYX9b",
	"icon": "icon_xdpk5"
};
module.exports = ___CSS_LOADER_EXPORT___;
